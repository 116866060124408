import React from 'react'
import View from '../View'
import Form from '../Form/email'
// import Tabs from './Tabs'
import { globalHistory } from '@reach/router'
import MultiStepForm from '../MultiStepForm'
import FormTab from '../MultiStepForm/FormTab'
import Slider from '../Slider'
import {
  sendEmail,
  setPageData,
  getEmailTemplate,
  getEmailLog,
  getUnsubscribed,
  getTargetability,
  checkFeatureChange } from '../../utils/email'
import {
  getSiteIndex
} from '../../utils/site-index'
import { parseQuery } from '../../utils/query'
import EmailPreview from '../EmailPreview'
import URLAutocomplete from '../URLAutocomplete'
import * as styles from '../Form/form.module.css'
import done from '../../../static/img/done.jpg'
import { getCurrentUser } from '../../utils/auth'
import {
  validateEmail
} from '../../utils/validation'
import RichTextEditor from './RichTextEditor'
import WaitLoader from '../WaitLoader'
require('../Tabs/tabs.module.css')

class Email extends React.Component {
  constructor(props) {
    super(props)
    var domain = 'https://www.forms-surfaces.com'
    var pageQuery = parseQuery(globalHistory.location.search)
    if(pageQuery[0]){
      var arr = pageQuery.split('/')
      domain = arr[0] + '//' + arr[2]
    }
    var user = getCurrentUser()
    if(user.fsOnly){
      if(user.fsOnly.websiteDomain){
        domain = user.fsOnly.websiteDomain
      }
    }
    this.state = {
      to: user.email,
      subject: '',
      userMessage: '',
      url: '',
      url2: '',
      url3: '',
      errorMessage: '',
      overrideTemplate: '',
      submitError: '',
      emailBody: '<p>Generating email from template.</p>',
      random: 0,
      errors: {},
      siteIndex: [],
      emailValidationMessage: '',
      emailValidMessage: '',
      emailsNotDeliverable: [],
      checkingDeliverability: false,
      isFormValid: false,
      domain: domain,
      showUserImage: false,
      query: {
        url: 'https://www.forms-surfaces.com/apex-bench'
      },
      useTemplateOverride: false,
      images: [],
      pages: [],
      recipientRows: 1,
      addressesChecked: true,
      targetability: [],
      unsubscribed: [],
      loading: true
    }

  }

  componentDidMount() {
    getSiteIndex(this.state.domain, function(err, siteIndex){
      if(err){
        console.log(err)
        this.setState({errorMessage: err.message});
        return
      }
      this.setState({
        siteIndex: siteIndex,
        loading: false
      })
    }.bind(this))
    var pageQuery = this.state.query;
    if(globalHistory.location.search){
      pageQuery = parseQuery(globalHistory.location.search)

      if(pageQuery.url){
        var url = pageQuery.url
        var arr = url.split('/')
        var domain = arr[0] + '//' + arr[2]
        this.setState({domain:domain})
        this.setState({ query: pageQuery }, function() {

          this.setState({url:this.state.query.url, loading: false});
          this.featureChange(this.state.query.url, 0);
          setPageData(this.state.query.url, false, function(err, data){
            if(err){
              this.setState({errorMessage: err.message});
              return
            }
            this.setState({ subject: data.title })
          }.bind(this))

        })
      }
      if(pageQuery.template){
        this.loadTemplate(pageQuery.template)
      }

      if(pageQuery.log){
        getEmailLog({logid: pageQuery.log}, function(err, response){
          if(err){
            console.log(err)
          }
          if(response){
            if(response.log){
              if(response.log.errorMessage){
                this.setState({errorMessage: response.log.errorMessage})
              }
              this.setState({
                subject: response.log.subject,
                userMessage: response.log.userMessage,
                domain: response.log.domain,
                pages: response.log.pagesShared,
                template: response.log.template,
                loading: false
              }, function(){
                this.validate()
              })
            }
          }
        }.bind(this))
      }
    }
    getUnsubscribed(function(err, data){
      if(err){
        console.log(err)
      }else{
        if(data.list){
          this.setState({
            unsubscribed: data.list
          });
        }
      }
    }.bind(this))
  }

  loadTemplate(templateId){
    getEmailTemplate({templateid: templateId}, function(err, response){
      if(err){
        console.log(err)
      }
      if(response){
        if(response.template){
          if(response.template.errorMessage){
            this.setState({errorMessage: response.template.errorMessage})
          }
          let useTemplateOverride = false
          if(response.template.overrideTemplate){
            useTemplateOverride = true
          }
          this.setState({
            subject: response.template.subject,
            userMessage: response.template.userMessage,
            domain: response.template.domain,
            showUserImage: response.template.showUserImage,
            pages: response.template.pagesShared,
            template: templateId,
            overrideTemplate: response.template.overrideTemplate,
            useTemplateOverride: useTemplateOverride,
            loading: false
          }, function(){
            this.validate()
          })
        }
      }
    }.bind(this))
  }

  validate() {
    var url = '';
    if(this.state.pages[0]){
      url = this.state.pages[0].url
    }
    var emails = this.emailsInValid()
    this.setState({errors: {
      to: emails,
      subject: this.state.subject.length < 1,
      url: url.length < 1,
      deliverability: !(this.state.addressesChecked && this.state.emailsNotDeliverable.length === 0)
    }}, function(){
      this.isFormValid()
    })
  }

  emailsInValid(){
    try{
      var isNotValid = true;
      var emails = this.state.to.split('\n')
      emails.forEach(function(element){
        if(element.trim()){
          var emailValid = validateEmail(element.trim())
          var unsubscribed = this.isUnsubscribed(element.toLowerCase())
          if(emailValid && !unsubscribed){
            isNotValid = false
          }else{
            console.log('is not valid')
            isNotValid = true;
            var InvalidEmailError = new Error(element+' is invalid')
            if(!emailValid){
              this.setState({emailValidationMessage:element+' is an invalid e-mail'})
            }
            if(unsubscribed){
              this.setState({emailValidationMessage:element+' has requested to be unsubscribed from communications'})
            }
            throw InvalidEmailError
          }
        }
      }.bind(this))
      // if(this.state.emailsNotDeliverable.length >= 1){
      //   isNotValid = true;
      // }
      this.setState({emailValidationMessage:''})
      return isNotValid
    }catch(err){
      return true
    }
  }

  isUnsubscribed(email){
    var found = this.state.unsubscribed.find(function(element){
      return element.recipient === email
    })
    return found
  }

  isFormValid(){
    let isValid = true;
    Object.keys(this.state.errors).forEach(function(key, index){
      if(this.state.errors[key] === true){
        isValid = false
      }
    }.bind(this))
    if(this.state.errorMessage){
      isValid = false;
    }
    this.setState({isFormValid: isValid})
  }

  handleUpdate(event) {
    if(event.target.name === 'userMessage'){
      if(event.target.value){
        event.target.value = event.target.value.replace(/<a\b[^>]*>/ig,"").replace(/<\/a>/ig, "")
      }
    }
    var updateObject = {
      [event.target.name]: event.target.value,
    }
    if(event.target.name === 'to'){
      var values = event.target.value.split('\n')
      if(event.target.value.split('\n').length > 10){
        values.splice(10,values.length - 10)
        updateObject.to = values.join('\n')
      }
      let addressesChecked = true
      //Don't bother checking F+S emails
      var noFSValues = values.filter(email => (!email.toLowerCase().includes('@forms-surfaces.com') && email.trim() !== ''))
      if(noFSValues.length > 0){
        addressesChecked = false
      }
      updateObject.addressesChecked = addressesChecked
      updateObject.emailValidMessage = ''
      updateObject.emailsNotDeliverable = []
    }
    console.log(updateObject)
    this.setState(updateObject, function(){
      this.validate()
    })
  }

  handleSubmit() {
    var updateMessage = function(text) {
      this.setState({ submitError: text })
    }.bind(this)
    sendEmail(this.state, function(message) {
      updateMessage(message)
    })
  }

  featureChange(value, index) {
    let currentState = {...this.state}
    console.log(value)
    checkFeatureChange(value, currentState, index, this.state.siteIndex, function(updateObject){
      this.setState(updateObject, function(){
        this.validate()
      })
    }.bind(this))
  }

  imageUpdate = (url, index) => {
    this.setState(prevState => {
      const pages = [...prevState.pages]
      pages[index].imageUrl = url
      return {pages:pages}
    })
  }

  getInitvalue = (index) => {
    if(this.state.pages[index]){
      return this.state.pages[index].url
    }
  }

  updatePageMessage = (value, index) => {
    this.setState(prevState => {
      const pages = [...prevState.pages]
      if(value){
        value = value.replace(/<a\b[^>]*>/i,"").replace(/<\/a>/i, "")
      }
      pages[index].userMessage = value
      return {pages:pages}
    })
  }

  handleAddressCheck = (e) => {
    e.preventDefault()
    this.setState({
      checkingDeliverability: true
    }, function(){
      var values = this.state.to.split('\n')
      values.splice(10,values.length - 10)
      //Don't bother checking F+S emails
      var noFSValues = values.filter(email => !email.toLowerCase().includes('@forms-surfaces.com'))
      let emailValidationMessage = ''
      let emailValidMessage = ''
      if(noFSValues.length > 0){
        getTargetability(noFSValues, function(err, data){
          var errors = this.state.errors
          let invalid = data.filter(item => item.IsValid === false)
          if(invalid.length > 0){
            errors.to = true
          }else{
            emailValidMessage = <div>All e-mails are able to send <i class="fas fa-check-circle"></i></div>
          }
          this.setState({
            errors: errors,
            emailValidMessage: emailValidMessage,
            addressesChecked: true,
            emailsNotDeliverable: invalid,
            checkingDeliverability: false
          }, function(){
            this.validate()
          })
        }.bind(this))
      }else{
        emailValidMessage = <div>All e-mails are able to send <i class="fas fa-check-circle"></i></div>
        var errors = this.state.errors
        errors.to = false
        this.setState({
          emailValidMessage: emailValidMessage,
          addressesChecked: true,
          emailsNotDeliverable: [],
          checkingDeliverability: false
        }, function(){
          this.validate()
        })
      }
    })
  }

  render() {
    let mailSent = <div><img src={done} alt="You did it!" /><p>
    High Five! Your email has been sent to {this.state.to}. Need to make changes? Click 'Back' to edit your email, or click 'Send Another Email' to send it to different recipients.</p></div>
    if(this.state.errorMessage){
      mailSent = <div><img src="https://media.giphy.com/media/mG8ys6DrOPk7m/giphy.gif" alt="Darn!" /><p>Something seems to have gone wrong in sending your email.</p><p>{this.state.submitError}</p></div>
    }
    let disableAddressCheck = this.state.addressesChecked
    if(this.state.errors){
      if(this.state.errors.to || this.state.addressesChecked){
        disableAddressCheck = true
      }
    }
    return (
      <>
      <View title="COMPOSE EMAIL">
      <div className="row col-xs-12">
      <WaitLoader loading={this.state.loading} />
      <p>Follow this step-by-step process to compose, preview, and send an individual email.</p>
      <p>First, type the email addresses of the contacts you want to target - only up to 10 recipients are allowed per email. <strong>NOTE:</strong> You have to 'Check Targetability' of all email addresses before previewing your email. While this requirement does not apply to forms-surfaces.com email addresses, it does apply to all other domain names to prevent multiple targeting of your Q contacts within ten days.</p>
      <p>Then, pick an engaging subject line for your email. Make sure to check the resource link for best practices to get some excellent pointers. Also, try to customize your intro message to grab your audience's attention. Your profile picture will be selected by default; uncheck it if you wish to remove it.</p>
      <p>Now, select your F+S website content: you can add up to three pages at a time by just typing the page's name and – if applicable - select the corresponding media and edit the related page content blurb.</p>
      <p>Finally, preview your email before sending it to your customers. It's that simple!</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12">
          <div className="box" style={{
            margin: '0px auto'
          }}>
          <MultiStepForm
            formValid={this.state.isFormValid}
          >
            <FormTab label="Compose" key="1" nextLabel="PREVIEW">
              <Form
                to={this.state.to}
                handleUpdate={e => this.handleUpdate(e)}
                handleSubmit={e => this.handleSubmit(e)}
                subject={this.state.subject}
                userMessage={this.state.userMessage}
                showUserImage={this.state.showUserImage}
                recipientRows={this.state.recipientRows}
                emailValidationMessage={this.state.emailValidationMessage}
                emailValidMessage={this.state.emailValidMessage}
                addressesChecked={disableAddressCheck}
                handleAddressCheck={this.handleAddressCheck}
                emailsNotDeliverable={this.state.emailsNotDeliverable}
                deliverabilityChecked={this.state.addressesChecked}
                checkingDeliverability={this.state.checkingDeliverability}
              />
              <label className={styles[`form__label`]}>
                First Feature
                <URLAutocomplete
                  siteIndex={this.state.siteIndex}
                  domain={this.state.domain}
                  initValue={this.getInitvalue(0)}
                  onChangeFunction={value => this.featureChange(value, 0)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[0] ? (
                <>
                <Slider images={this.state.pages[0].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[0].imageUrl} index="0" />
                <div style={{
                  display: (this.state.useTemplateOverride ? 'none' : 'block')
                }}>
                <RichTextEditor
                  onUpdate={this.updatePageMessage}
                  body={this.state.pages[0].userMessage}
                  index={0}
                  name="test"
                  />
                </div>
                </>) : null}
              <label className={styles[`form__label`]} style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
                Second Feature
                <URLAutocomplete
                  siteIndex={this.state.siteIndex}
                  domain={this.state.domain}
                  initValue={this.getInitvalue(1)}
                  onChangeFunction={value => this.featureChange(value, 1)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[1] ? (
                <div style={{
                  display: (this.state.useTemplateOverride ? 'none' : 'block')
                }}>
                <Slider images={this.state.pages[1].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[1].imageUrl} index="1" />
                <RichTextEditor
                  onUpdate={this.updatePageMessage}
                  body={this.state.pages[1].userMessage}
                  index={1}
                  />
                </div>) : null}
              <label className={styles[`form__label`]} style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
                Third Feature
                <URLAutocomplete
                  siteIndex={this.state.siteIndex}
                  domain={this.state.domain}
                  initValue={this.getInitvalue(2)}
                  onChangeFunction={value => this.featureChange(value, 2)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[2] ? (
                <div style={{
                  display: (this.state.useTemplateOverride ? 'none' : 'block')
                }}>
                <Slider images={this.state.pages[2].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[2].imageUrl} index="2" />
                <RichTextEditor
                  onUpdate={this.updatePageMessage}
                  body={this.state.pages[2].userMessage}
                  index={2}
                  />
                </div>) : null}
            </FormTab>
            <FormTab label="PREVIEW" key="2" nextLabel="SEND EMAIL" prevLabel="BACK">
              <EmailPreview
                to={this.state.to}
                subject={this.state.subject}
                userMessage={this.state.userMessage}
                domain={this.state.domain}
                showUserImage={this.state.showUserImage}
                pages={this.state.pages}
                overrideTemplate={this.state.overrideTemplate}
              />
              <p>If the email preview looks good, go on and click 'Send Email.' Need to make changes? Click 'Back' to edit your email.</p>
            </FormTab>
            <FormTab label="SEND" key="3" prevLabel="BACK" nextLabel="" nextAction={this.handleSubmit.bind(this)} resetLabel="Send Another Email">
              {mailSent}
            </FormTab>
          </MultiStepForm>
          <label>{this.state.errorMessage}</label>
          </div>
        </div>
        </div>
      </View>
      </>
    )
  }
}



export default Email
